import React, { useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import './demo.css';

const Demo = () => {
    const [balance, setBalance] = useState(1000);
    const [amount, setAmount] = useState('')
    const [charges, setCharges] = useState(20)

    const handleChange = (e) => {
        setAmount(e.target.value)
    }

    const handleWithdrawal = (e) => {
        e.preventDefault();
        const amountVal = parseInt(amount)
        const sentAmount = amountVal - charges;
        if(amount===''){
            return alert('Please enter an amount')
        }
        // if (parseInt(amount) <= 10){
        //     return alert('Withdrawal should be up to 11Pv and above')
        // }
        if (amountVal > balance){
            return alert('insufficient balance, cannot make withdrawal')
        }else{
            alert ('All Transanctions comes with charges of ₦20')
            alert ('Transanction successful')
            alert('You have been paid ₦' + sentAmount)
            setBalance(balance - amountVal)
        }
            
    }
    return (
        <div className='demo'>
            <p>Available Balance <b>₦{balance}</b></p>
            <Form onSubmit={handleWithdrawal}>
                <FloatingLabel
                    controlId="amountInput"
                    label="Enter Amount"
                    className="mb-3"
                >
                    <Form.Control type="text" name='amount' placeholder='Enter Amount' onChange={handleChange} value={amount} />
                </FloatingLabel>
                <small><b>Note: </b>All Transactions incures charges of ₦20</small><br/>
                <Button variant='success' type='submit'>Make Withdrawal</Button>
            </Form>
        </div>
    )
}

export default Demo