import React from 'react';
import './pageNotFound.css';

const PageNotFound = () => {

  return (
    <div>Oops!! Seems you missed your way</div>
  )
}

export default PageNotFound