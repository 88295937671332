import React from 'react';
import { Button } from 'react-bootstrap';
import './genComponents.css';

const Loader = () => {
  return (
    <Button variant='success' className='loader'>
        <div className='dot-1'></div>
        <div className='dot-2'></div>
        <div className='dot-3'></div>
    </Button>
  )
}

export default Loader